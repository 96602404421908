// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-insticator-js": () => import("../src/templates/insticator.js" /* webpackChunkName: "component---src-templates-insticator-js" */),
  "component---src-templates-widget-js": () => import("../src/templates/widget.js" /* webpackChunkName: "component---src-templates-widget-js" */),
  "component---src-templates-about-js": () => import("../src/templates/about.js" /* webpackChunkName: "component---src-templates-about-js" */),
  "component---src-templates-legal-js": () => import("../src/templates/legal.js" /* webpackChunkName: "component---src-templates-legal-js" */),
  "component---src-templates-contact-js": () => import("../src/templates/contact.js" /* webpackChunkName: "component---src-templates-contact-js" */),
  "component---src-templates-index-js": () => import("../src/templates/index.js" /* webpackChunkName: "component---src-templates-index-js" */),
  "component---src-templates-all-js": () => import("../src/templates/all.js" /* webpackChunkName: "component---src-templates-all-js" */),
  "component---src-templates-post-js": () => import("../src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */),
  "component---src-pages-404-js": () => import("../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */)
}

